import React, { useEffect } from "react";
import FilterComp from "../../components/FilterComp";
import JobsTabPage from "../../components/JobsTabPage";
import PagingWithNextAndPrevButtons from "../../components/PagingNextPrevious";
import SearchComp from "../../components/SearchComp";
import TabCards from "../../components/TabCards";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useAxios } from "../../store";
import { TABS } from "../../utils/helpers";
import TooltipComp from "../../components/shared/reusable/TooltipComp";

function HomePage({ tabsActive, setTabsActive, setPage, page }) {
  const {
    getJobsListHandler,
    listTotal,
    getOngoingListHandler,
    ongoingTotal,
    getCompletedListHandler,
    startDeliveryData,
    completedListToltal,
    isSandbox,
    filterArray,
    deliverySearchQuery,
    setDeliverySearchQuery,
  } = useAxios();
  const { smFontSize, mdFontSize } = useDesktopOrLaptop();

  const fetchPartnerDeliveries = (fetchForTab = false) => {
    const params = { page };
    if (filterArray?.length) {
      params.vehicle = filterArray.toString();
    }
    if (deliverySearchQuery) {
      params.searchKey = deliverySearchQuery;
    }
    if (!fetchForTab || fetchForTab === TABS.JOBS) {
      getJobsListHandler(params);
    }
    if (!fetchForTab || fetchForTab === TABS.ONGOING) {
      getOngoingListHandler(params);
    }
    if (!fetchForTab || fetchForTab === TABS.COMPLETED) {
      getCompletedListHandler(params);
    }
  };

  useEffect(() => {
    fetchPartnerDeliveries(tabsActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsActive, page, startDeliveryData]);

  useEffect(() => {
    fetchPartnerDeliveries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSandbox, deliverySearchQuery, filterArray]);

  return (
    <div
      style={{
        padding: "20px",
        height: "100%",
        width: "100%",
      }}
      className=" flex flex-col kata trans"
    >
      <section className="flex items-center justify-between ">
        <TabCards
          setPage={setPage}
          setTabsActive={setTabsActive}
          tabsActive={tabsActive}
          title="jobs"
          total={listTotal}
          mdFontSize={mdFontSize}
          smFontSize={smFontSize}
          // newSum={2}
        />
        <TabCards
          setPage={setPage}
          setTabsActive={setTabsActive}
          tabsActive={tabsActive}
          title="ongoing"
          total={ongoingTotal}
          mdFontSize={mdFontSize}
          smFontSize={smFontSize}
          // newSum={3}
        />
        <TabCards
          setPage={setPage}
          setTabsActive={setTabsActive}
          tabsActive={tabsActive}
          total={completedListToltal}
          title="completed"
          mdFontSize={mdFontSize}
          smFontSize={smFontSize}
        />
      </section>
      <section>
        <div
          style={{}}
          className="flex items-center justify-between relative mt-5"
        >
          <SearchComp
            setSearchQuery={setDeliverySearchQuery}
            setPage={setPage}
            page={page}
          />
          <FilterComp page={page} setPage={setPage} />
        </div>
      </section>
      <section style={{ marginBottom: "40px" }}>
        {tabsActive === "jobs" ? (
          <>
            <JobsTabPage jobs page={page} />
            <PagingWithNextAndPrevButtons
              getData={getJobsListHandler}
              active={tabsActive}
              page={page}
              setPage={setPage}
              result={Math.ceil(listTotal / 30)}
            />
          </>
        ) : tabsActive === "ongoing" ? (
          <>
            <JobsTabPage ongoing />
            <PagingWithNextAndPrevButtons
              getData={getOngoingListHandler}
              active={tabsActive}
              page={page}
              setPage={setPage}
              result={Math.ceil(ongoingTotal / 30)}
            />
          </>
        ) : (
          <>
            <JobsTabPage completed />
            <PagingWithNextAndPrevButtons
              getData={getCompletedListHandler}
              active={tabsActive}
              page={page}
              setPage={setPage}
              result={Math.ceil(completedListToltal / 30)}
            />
          </>
        )}
      </section>
      <section
        style={{}}
        // style={{ position: "absolute", bottom: "24px", right: "24px" }}
      ></section>
    </div>
  );
}

export default HomePage;
