import React, { useState, useEffect } from "react";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { toast } from "react-toastify";
import moment from "moment";
import { CgClose } from "react-icons/cg";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import Colors from "../../assets/Colors";
import DriverInfoForModal from "../DriverInfoForModal";
import SingleAddressForModal from "../SingleAddressForModal";
import SingleItemForModal from "../SingleItemForModal";

import UploadProofs from "../UploadProofs";
import useAxios from "../../store/useAxios";
import { handleUpload, updateDeliveryApiHandler } from "./utils";
import { getPresignedUrl, uploadToS3 } from "../UploadProofs/utils";
import { Loading } from "../../assets/svgs/AllSvgs";
import LoaderComp from "../Reusable/LoaderComp";

export const mapUndeliverableActions = {
  return_to_store: "Return to store",
  leave_at_door: "Leave at door",
  discard_package: "Discard package",
};

const statuses = [
  { back: "scheduled", front: "Scheduled" },
  { back: "delivery_created", front: "Delivery Created" },
  { back: "driver_not_assigned", front: "Assigning Driver" },
  { back: "driver_assigned", front: "Driver Assigned" },
  { back: "enroute_pickup", front: "Enroute to Pick-up" },
  { back: "arrived_at_pickup", front: "Arrived at Pick-up" },
  { back: "pickup_complete", front: "Pick-up Complete" },
  { back: "enroute_dropoff", front: "Enroute to Drop-off" },
  { back: "arrived_at_dropoff", front: "Arrived at Drop-off" },
  { back: "dropoff_complete", front: "Drop off Completed" },
  { back: "delivered", front: "Delivered" },
  { back: "contacting_provider", front: "Contacting Provider" },
  { back: "enroute_to_return", front: "Enroute to Return" },
  { back: "expired", front: "Expired" },
  { back: "disputed", front: "Disputed" },
  { back: "returned", front: "Returned" },
];

function SeeMoreModal({
  setShow,
  completed,
  itemForSeeMoreModal,
  setItemForSeeMoreModal,
  page,
  isJobs,
}) {
  const today = moment().format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
  const { smFontSize, mdFontSize, isdesktop } = useDesktopOrLaptop();
  const { getOngoingListHandler } = useAxios();

  const {
    getStartDeliveryHandler,
    getDeliveryByIdHandler,
    setShowStartDeliveryModal,
    setItemForStartDeliveryModal,
    getUserUnfoHandler,
    getDeclineJobHandler,
    getJobsListHandler,
    getAcceptOneJob,
  } = useAxios();

  const [podImages, setPodImages] = useState([]);
  const [signature, setSignature] = useState([]);
  const [pickupEta, setPickupEta] = useState(itemForSeeMoreModal?.pickup_eta);
  const [dropoffEta, setDropoffEta] = useState(
    itemForSeeMoreModal?.dropoff_eta
  );

  const [activePage, setActivePage] = useState("addresses");
  useEffect(() => {
    if (itemForSeeMoreModal?.auth0UserId) {
      getUserUnfoHandler(itemForSeeMoreModal.auth0UserId);
    }
    return () => {
      setPodImages([]);
      setSignature([]);
    };
  }, [itemForSeeMoreModal]);

  const cancelOffer = (id) => {
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      const params = { page };
      getOngoingListHandler(params);
      setShow(false);
      toast.success(`Offer decline successfully`);
    };
    getDeclineJobHandler(id, callback);
  };

  const accpetOffer = (item) => {
    setIsLoading(true);
    const callback = (err, res) => {
      setIsLoading(false);
      if (err) {
        toast.error(err);
        return;
      }
      const params = { page };
      getJobsListHandler(params);
      toast.success(`Offer accepted successfully`);
      setShow(false);
    };
    const payload = {
      // pickup_address: item.pickupAddress,
      // pickup_name: item.pickup_name,
      // pickup_notes: item.pickup_notes,
      // pickup_phone_number: item.pickup_phone_number,
      // pickup_unit: item.pickup_unit,
      // items_description: item.items_description,
      // external_order_ref: item.external_order_ref,
      // dropoff_unit: item.dropoff_unit,
      // dropoff_address: item.pickup_address,
      // dropoff_name: item.dropoff_name,
      // dropoff_phone_number: item.dropoff_phone_number,
      // tip: item.tip,
      // order_value: item.order_value,
      // items: item.items,
      // pickup_at: item.pickup_at,
      // dropoff_at: item.dropoffAt,
      delivery_id: item.id,
    };
    getAcceptOneJob(payload, callback);
  };

  const handleDateChange = (e, dateStamp, setDateStamp) => {
    const newDate = moment(e.target.value, "YYYY-MM-DD");
    const currentEta = moment(dateStamp);
    const updatedEta = currentEta
      .set({
        year: newDate.year(),
        month: newDate.month(),
        date: newDate.date(),
      })
      .toISOString();
    setDateStamp(updatedEta);
  };

  const handleTimeChange = (e, dateStamp, setDateStamp) => {
    const newTime = moment(e.target.value, "HH:mm");
    const currentEta = moment(dateStamp);
    const updatedEta = currentEta
      .set({
        hour: newTime.hour(),
        minute: newTime.minute(),
      })
      .toISOString();
    setDateStamp(updatedEta);
  };

  const [isLoading, setIsLoading] = useState(false);

  const deliveryUpdateHandler = async () => {
    setIsLoading(true);
    const s3Pods = await handleUpload(
      podImages,
      getPresignedUrl,
      itemForSeeMoreModal?.id,
      uploadToS3
    );
    const s3signatures = await handleUpload(
      signature,
      getPresignedUrl,
      itemForSeeMoreModal?.id,
      uploadToS3
    );

    const payload = {
      pickup_eta: pickupEta,
      dropoff_eta: dropoffEta,
      proof_of_delivery: s3Pods?.length > 0 ? s3Pods : undefined,
      signature: s3signatures[0],
    };
    // console.log("payload", payload);
    // return;
    const data = await updateDeliveryApiHandler(
      itemForSeeMoreModal?.id,
      payload
    );
    if (data?.data) {
      setItemForSeeMoreModal(data.data);
    }
    getOngoingListHandler({ page });

    setPodImages([]);
    setSignature([]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (itemForSeeMoreModal?.pickup_eta) {
      setPickupEta(itemForSeeMoreModal?.pickup_eta);
    }
    if (itemForSeeMoreModal?.dropoff_eta) {
      setDropoffEta(itemForSeeMoreModal?.dropoff_eta);
    }
  }, [itemForSeeMoreModal]);

  return (
    <div
      className="kata"
      style={{
        ...styles.container,
        ...{
          padding: "0px 0px 150px 0px",
          margin: "0px 0px 30px 0px",
          width: isdesktop ? "60vw" : "90vw",
        },
      }}
    >
      <div className="flex items-center" style={styles.header}>
        <div
          style={{ marginBottom: "24px" }}
          className="flex flex-row items-center"
        >
          <button
            id="addressesBtnId"
            style={{
              ...styles.buttons,
              ...{
                color: activePage === "addresses" ? "#0062FF" : "#979797",
                width: "80px",
                fontSize: mdFontSize,
              },
            }}
            type="button"
            onClick={() => {
              setActivePage("addresses");
            }}
            className={`focus:outline-none flex flex-col`}
          >
            Addresses
            {activePage === "addresses" && (
              <div style={styles.bottomLine}></div>
            )}
          </button>
          {/* hiding item because edward is not providing item detail but I am sure he is neglecting it for now but its a required feature */}
          <button
            id="itemsBtnId"
            style={{
              ...styles.buttons,
              ...{
                color: activePage === "items" ? "#0062FF" : "#979797",
                marginLeft: "80px",
                fontSize: mdFontSize,
              },
            }}
            type="button"
            onClick={() => {
              setActivePage("items");
            }}
            className={`focus:outline-none flex flex-col`}
          >
            Items
            {activePage === "items" && (
              <div style={{ ...styles.bottomLine, ...{ width: "100%" } }}></div>
            )}
          </button>
          {/* {itemForSeeMoreModal?.started && (
            <button
              id="driverBtnId"
              style={{
                ...styles.buttons,
                ...{
                  color: activePage === "driver" ? "#0062FF" : "#979797",
                  marginLeft: "80px",
                  fontSize: mdFontSize,
                },
              }}
              type="button"
              onClick={() => {
                setActivePage("driver");
              }}
              className={`focus:outline-none flex flex-col`}
            >
              Driver
              {activePage === "driver" && (
                <div
                  style={{ ...styles.bottomLine, ...{ width: "100%" } }}
                ></div>
              )}
            </button>
          )} */}
        </div>
      </div>

      {itemForSeeMoreModal?.status !== "driver_not_assigned" && !isJobs && (
        <div
          className="flex items-center"
          style={{
            width: "100%",
          }}
        >
          <div
            className="flex items-center justify-start w-full scrollbar"
            style={{
              padding: "0px 20px",
              overflowX: "scroll",
              width: "100%",
              margin: "0 2%",
              paddingBottom: "10px",
            }}
          >
            <div
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                color: Colors.fontDark,
                textWrap: "nowrap",
              }}
            >
              Change Status:
            </div>
            {statuses.map((item, index) => {
              if (
                itemForSeeMoreModal?.delivery_type !== "scheduled" &&
                item.back === "scheduled"
              ) {
                return <></>;
              }
              return (
                <button
                  id={`${item.back}StatusesId`}
                  type="button"
                  onClick={async () => {
                    const params = {
                      status: item.back,
                      driverName: itemForSeeMoreModal?.courier_name ?? "",
                      driverPhone:
                        itemForSeeMoreModal?.courier_phone_number ?? "",
                      vehicleType:
                        itemForSeeMoreModal?.courier_vehicle_type ?? "",
                    };
                    await getStartDeliveryHandler(
                      itemForSeeMoreModal?.id,
                      params
                    );
                    const _itemForSeeMoreModal = { ...itemForSeeMoreModal };
                    _itemForSeeMoreModal.status = item.back;
                    setItemForSeeMoreModal(_itemForSeeMoreModal);
                    // itemForSeeMoreModal.status = item.back;
                    // await getDeliveryByIdHandler(itemForSeeMoreModal?.id);
                  }}
                  key={index}
                  className="flex items-center"
                  style={{
                    fontFamily: "Roboto",
                    textWrap: "nowrap",
                    fontWeight: "500",
                    color:
                      itemForSeeMoreModal?.status === item.back
                        ? Colors.bluePrimary
                        : Colors.fontGreyLight,
                    marginLeft: "12px",
                    fontSize: mdFontSize,
                  }}
                >
                  {itemForSeeMoreModal?.status === item.back ? (
                    <BiCheckboxChecked size={22} color={Colors.bluePrimary} />
                  ) : (
                    <BiCheckbox size={22} color={Colors.fontGreyLight} />
                  )}
                  {item.front}
                </button>
              );
            })}
          </div>
        </div>
      )}

      <button
        type="button"
        onClick={() => {
          setShow(false);
          setActivePage("addresses");
        }}
        style={styles.closeButton}
      >
        <CgClose size={22} />
      </button>
      {activePage === "addresses" ? (
        <div
          className="scrollbar"
          style={{
            height: "100%",
            margin: "20px 0px 0px 0px",
            padding: "0px 0px 50px 0px",
            overflowY: "scroll",
            maxHeight: "100%",
          }}
        >
          {itemForSeeMoreModal && (
            <SingleAddressForModal
              index={0}
              styles={styles}
              address={itemForSeeMoreModal?.pickup_address}
              bussinessNmae="Burq"
              name={itemForSeeMoreModal?.pickup_name}
              note={itemForSeeMoreModal?.pickup_notes}
              phoneNumber={itemForSeeMoreModal?.pickup_phone_number}
              smFontSize={smFontSize}
              mdFontSize={mdFontSize}
            />
          )}
          {itemForSeeMoreModal && (
            <SingleAddressForModal
              length={1}
              index={1}
              styles={styles}
              address={itemForSeeMoreModal?.pickup_address}
              bussinessNmae="Burq"
              name={itemForSeeMoreModal?.dropoff_name}
              note={itemForSeeMoreModal?.dropoff_notes}
              phoneNumber={itemForSeeMoreModal?.dropoff_phone_number}
            />
          )}
          {/* {itemForSeeMoreModal?.dropoffs?.map((item, index) => {
            return (
              <SingleAddressForModal
                length={itemForSeeMoreModal?.dropoffs?.length}
                key={index}
                index={index + 1}
                styles={styles}
                address={item.dropoff_address}
                bussinessNmae="Burq"
                name={item.dropoff_name}
                note={item.dropoff_notes}
                phoneNumber={item.dropoff_phone_number}
              />
            );
          })} */}
          <div
            style={{ padding: "0px 50px", marginTop: "20px" }}
            className="flex w-full items-start justify-start mt-7 overflow-x-auto "
          >
            <div style={{ marginRight: "20px", width: "50%" }}>
              <div>
                <div
                  style={{
                    color: Colors.fontGreyLight,
                  }}
                  className="font-semibold mt-2"
                >
                  Delivery Fee:{" "}
                  <span style={{ color: Colors.fontDark }}>
                    $
                    {(itemForSeeMoreModal?.fee / 100).toFixed(2) ||
                      " Not Available"}
                  </span>
                </div>
                <div
                  style={{
                    color: Colors.fontGreyLight,
                  }}
                  className="font-semibold mt-2"
                >
                  Delivery Tip:{" "}
                  <span style={{ color: Colors.fontDark }}>
                    $
                    {(itemForSeeMoreModal?.tip / 100).toFixed(2) ||
                      " Not Available"}
                  </span>
                </div>
                <div
                  style={{
                    color: Colors.fontGreyLight,
                  }}
                  className="font-semibold mt-2"
                >
                  Scheduled Time{" "}
                  {itemForSeeMoreModal?.dropoff_at ? "Dropoff" : "pickup"}:{" "}
                  <span style={{ color: Colors.fontDark }}>
                    {itemForSeeMoreModal?.dropoff_at &&
                      (moment(itemForSeeMoreModal.dropoff_at).format(
                        "D MMM YYYY, hh:mmA"
                      ) === "Invalid date"
                        ? ""
                        : moment(itemForSeeMoreModal.dropoff_at).format(
                            "D MMM YYYY, hh:mmA"
                          ))}
                    {itemForSeeMoreModal?.pickup_at &&
                      (moment(itemForSeeMoreModal.pickup_at).format(
                        "D MMM YYYY, hh:mmA"
                      ) === "Invalid date"
                        ? ""
                        : moment(itemForSeeMoreModal.pickup_at).format(
                            "D MMM YYYY, hh:mmA"
                          ))}
                  </span>
                </div>

                <div
                  style={{
                    color: Colors.fontGreyLight,
                  }}
                  className="font-semibold mt-2"
                >
                  External Order Id:{" "}
                  <span style={{ color: Colors.fontDark }}>
                    {itemForSeeMoreModal?.external_order_ref ||
                      " Not Available"}
                  </span>
                </div>
                <div
                  style={{
                    color: Colors.fontGreyLight,
                  }}
                  className="font-semibold mt-2"
                >
                  Provider Id:{" "}
                  <span style={{ color: Colors.fontDark }}>
                    {itemForSeeMoreModal?.short_id || " Not Available"}
                  </span>
                </div>
                {itemForSeeMoreModal?.reroute_child_delivery_id && (
                  <div
                    style={{
                      color: Colors.fontGreyLight,
                    }}
                    className="font-semibold mt-2"
                  >
                    Delivery rerouted. New ID:
                    <span style={{ color: Colors.fontDark, marginLeft: "5px" }}>
                      {itemForSeeMoreModal?.reroute_child_delivery_id}
                    </span>
                  </div>
                )}
                {itemForSeeMoreModal?.reroute_parent_delivery_id && (
                  <div
                    style={{
                      color: Colors.fontGreyLight,
                    }}
                    className="font-semibold mt-2"
                  >
                    This is rerouted Delivery from Delivery ID:
                    <span style={{ color: Colors.fontDark, marginLeft: "5px" }}>
                      {itemForSeeMoreModal?.reroute_parent_delivery_id}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
                  Order Value:
                </div>
                <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                  {itemForSeeMoreModal?.order_value == 0
                    ? "$ 0"
                    : itemForSeeMoreModal?.order_value
                    ? "$" + itemForSeeMoreModal?.order_value / 100
                    : ""}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
                  Driver Vehicle:
                </div>
                <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                  {itemForSeeMoreModal?.vehicle
                    ? itemForSeeMoreModal.vehicle
                    : ""}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
                  Vehicle:
                </div>
                <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                  {itemForSeeMoreModal?.vehicle_type
                    ? itemForSeeMoreModal.vehicle_type
                    : ""}
                </div>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
                  Delivery type:
                </div>
                <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                  {itemForSeeMoreModal?.pickup_at ? "Scheduled" : "ASAP"}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
                  Proof:
                </div>
                <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                  {itemForSeeMoreModal?.signature_requested
                    ? "Signature"
                    : itemForSeeMoreModal?.contactless_requested
                    ? "Contactless"
                    : "No Preference"}
                </div>
              </div>

              {itemForSeeMoreModal?.cancellation_reason && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    // height: "100%",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: Colors.fontDark,
                      width: "100%",
                      backgroundColor: Colors.whiteBlue,
                      height: "31px",
                      alignItems: "center",
                      display: "flex",
                      borderRadius: "5px 5px 0px 0px",
                      paddingLeft: "12px",
                    }}
                  >
                    Cancellation Reason
                  </div>
                  <div
                    className="kata"
                    style={{
                      width: "100%",
                      backgroundColor: Colors.whitestBlue,
                      padding: isdesktop ? "30px" : "50px",
                      maxHeight: "530px",
                      overflowY: "scroll",
                    }}
                  >
                    {itemForSeeMoreModal.cancellation_reason || ""}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
                  Undeliverable Action:
                </div>
                <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                  {itemForSeeMoreModal?.undeliverable_action_requested
                    ? mapUndeliverableActions[
                        itemForSeeMoreModal.undeliverable_action_requested
                      ]
                    : "No Preference"}
                </div>
              </div>
            </div>
          </div>
          {!completed &&
            !isJobs &&
            itemForSeeMoreModal?.status !== "driver_not_assigned" && (
              <>
                <div
                  style={{ padding: "0px 50px", margin: "20px 0px" }}
                  className="flex w-full items-start justify-start mt-7 overflow-x-auto "
                >
                  <UploadProofs
                    images={podImages}
                    setImages={setPodImages}
                    helperText={"Max 3 images"}
                    title={"Proof Of Delivery"}
                    inputId="pod"
                    max={3}
                  />
                </div>
                <div
                  style={{ padding: "0px 50px", margin: "20px 0px" }}
                  className="flex w-full items-start justify-start mt-7 overflow-x-auto "
                >
                  <UploadProofs
                    images={signature}
                    setImages={setSignature}
                    helperText={"Max 1 image"}
                    title={"Signature"}
                    inputId="signature"
                    max={1}
                  />
                </div>
              </>
            )}

          {!completed &&
            !isJobs &&
            itemForSeeMoreModal?.status !== "driver_not_assigned" && (
              <div
                style={{ padding: "0px 50px", margin: "20px 0px" }}
                className="flex flex-col w-1/2 items-start justify-start mt-7 overflow-x-auto "
              >
                <div style={styles.inputLable}>Pickup ETA</div>
                <div style={styles.inputForEdit}>
                  <input
                    onChange={(e) => {
                      handleDateChange(e, pickupEta, setPickupEta);
                    }}
                    name="pickupDateEta"
                    min={today}
                    value={
                      pickupEta
                        ? moment(pickupEta).format("YYYY-MM-DD")
                        : undefined
                    }
                    placeholder="Type here"
                    type="date"
                    style={styles.input}
                  />
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, pickupEta, setPickupEta);
                    }}
                    placeholder="Type here"
                    name="pickupTimeETA"
                    value={
                      pickupEta ? moment(pickupEta).format("HH:mm") : undefined
                    }
                    min={
                      moment(pickupEta).isSame(today, "day")
                        ? currentTime
                        : "00:00"
                    }
                    type="time"
                    style={styles.input}
                  />
                </div>
                <div style={styles.inputLable}>Dropoff ETA</div>
                <div style={styles.inputForEdit}>
                  <input
                    onChange={(e) => {
                      handleDateChange(e, dropoffEta, setDropoffEta);
                    }}
                    name="dropoffDateEta"
                    min={today}
                    value={
                      dropoffEta
                        ? moment(dropoffEta).format("YYYY-MM-DD")
                        : undefined
                    }
                    placeholder="Type here"
                    type="date"
                    style={styles.input}
                  />
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, dropoffEta, setDropoffEta);
                    }}
                    name="dropoffTimeETA"
                    value={
                      dropoffEta
                        ? moment(dropoffEta).format("HH:mm")
                        : undefined
                    }
                    min={
                      moment(pickupEta).isSame(today, "day")
                        ? currentTime
                        : "00:00"
                    }
                    placeholder="Type here"
                    type="time"
                    style={styles.input}
                  />
                </div>
              </div>
            )}
        </div>
      ) : activePage === "items" ? (
        <div className="kata" style={styles.tabContainer}>
          {itemForSeeMoreModal?.items?.map((item, index) => {
            return (
              <SingleItemForModal
                name={item.name}
                quantity={item.quantity}
                size={item.size}
                key={index}
              />
            );
          })}
        </div>
      ) : (
        activePage === "driver" && (
          <div className="kata" style={styles.tabContainer}>
            <DriverInfoForModal
              getDeliveryByIdHandler={getDeliveryByIdHandler}
              getStartDeliveryHandler={getStartDeliveryHandler}
              item={itemForSeeMoreModal}
              completed={completed}
            />
          </div>
        )
      )}

      {!completed &&
      !isJobs &&
      itemForSeeMoreModal?.status === "driver_not_assigned" ? (
        <div style={styles.bottomContainer}>
          <button
            type="button"
            onClick={() => {
              setShowStartDeliveryModal(true);
              setItemForStartDeliveryModal(itemForSeeMoreModal);
              setShow(false);
            }}
            style={{
              ...styles.button,
              ...{
                backgroundColor: Colors.bluePrimary,
                marginRight: "10px",
                fontSize: mdFontSize,
              },
            }}
          >
            Start Delivery
          </button>
          <button
            type="button"
            onClick={() => cancelOffer(itemForSeeMoreModal?.id)}
            style={{
              ...styles.button,
              ...{
                backgroundColor: Colors.buttonBg,
                color: Colors.fontDark,
                marginLeft: "10px",
                fontSize: mdFontSize,
              },
            }}
          >
            Decline
          </button>
        </div>
      ) : (
        !isJobs && (
          <div style={styles.bottomContainer}>
            <button
              type="button"
              onClick={() => deliveryUpdateHandler()}
              style={{
                ...styles.button,
                ...{
                  backgroundColor: Colors.bluePrimary,
                  marginRight: "10px",
                  fontSize: mdFontSize,
                },
              }}
            >
              {isLoading ? (
                <div className="w-full flex justify-center">
                  <Loading color="#ECF0F1" className="h-6 w-6 animate-spin" />{" "}
                </div>
              ) : (
                "Update Delivery"
              )}
            </button>
            <button
              type="button"
              onClick={() => cancelOffer(itemForSeeMoreModal?.id)}
              style={{
                ...styles.button,
                ...{
                  backgroundColor: Colors.buttonBg,
                  color: Colors.fontDark,
                  marginLeft: "10px",
                  fontSize: mdFontSize,
                },
              }}
            >
              Decline
            </button>
          </div>
        )
      )}
      {isJobs && (
        <div
          style={{
            width: "100%",
            display: "flex",
            position: "fixed",
            padding: "30px",
            bottom: 0,
            left: 0,
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <button
            id="acceptJobId"
            type="button"
            onClick={async () => accpetOffer(itemForSeeMoreModal)}
            style={{
              ...styles.button,
              ...{ backgroundColor: Colors.bluePrimary, marginRight: "10px" },
            }}
          >
            {isLoading ? (
              <LoaderComp loading={isLoading} color="#FFFFF" />
            ) : (
              "Accept"
            )}
          </button>
          <button
            type="button"
            onClick={async () => {
              cancelOffer(itemForSeeMoreModal.id);
            }}
            style={{
              ...styles.button,
              ...{
                backgroundColor: Colors.buttonBg,
                color: Colors.fontDark,
                marginLeft: "10px",
              },
            }}
          >
            Decline
          </button>
        </div>
      )}
    </div>
  );
}
const styles = {
  container: {
    height: "76vh",
    position: "relative",
    overflowY: "hidden",
    // margin: "0px 0px 90px 0px",
  },
  header: {
    width: "100%",
    height: "73px",
    padding: "20px 50px 0px 50px",
    // marginTop: "30px",
    // position: "fixed",
    backgroundColor: Colors.white,
    borderRadius: "8px",
    zIndex: 100,
  },
  closeButton: {
    position: "fixed",
    top: "20px",
    right: "20px",
    zIndex: 200,
  },
  button: {
    borderRadius: "8px",
    textAlign: "center",
    padding: "7px 20px",
    fontFamily: "Poppins",
    fontWeight: "600",
    color: Colors.white,
  },
  buttons: {
    fontFamily: "Poppins",
    fontWeight: "600",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomLine: {
    width: "80px",
    height: "4px",
    backgroundColor: Colors.bluePrimary,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  bottomContainer: {
    width: "100%",
    display: "flex",
    position: "fixed",
    padding: "15px 30px",
    bottom: "15px",
    left: 0,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: Colors.white,
    borderRadius: "8px",
  },
  tabContainer: {
    height: "100%",
    marginTop: "20px",
    padding: "0px 50px 0px 50px",
    overflowY: "scroll",
  },
  input: {
    fontFamily: "Poppins",
    fontSize: "14px",
    border: "0px",
    padding: "3px 10px",
    borderRadius: "8px",
    outlineWidth: "0px",
    width: "100%",
    fontWeight: "500",
  },
  inputLable: {
    fontFamily: "Poppins",
    fontWeight: "500",
    color: Colors.fontGreyLight,
    fontSize: "16px",
    marginTop: "30px",
    marginBottom: "10px",
  },
  inputForEdit: {
    width: "100%",
    borderWidth: "1px",
    border: `solid ${Colors.borderPrimary}`,
    padding: "6px 4px",
    borderRadius: "10px",
    display: "flex",
  },
};

export default SeeMoreModal;
